import React, { lazy, useEffect } from 'react';
import './index.css';
import './styles/styles.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { Header } from './components/Header/Header';
import Hero from './components/Hero/Hero';
import LazyCart from 'components/Cart/LazyCart';
import FloatingCTA from 'components/Floater/Floater';
import ProductHero from 'components/ProductHero/ProductHero';
import UpsellModal from 'components/UpsellModal/UpsellModal';
import Support from 'components/Zendesk';
import LazyComponent from 'components/LazyLaod';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

const Layout = () => {
  const { Layout, Theme } = usewpdataSelectors();

  return (
    <>
      <div
        style={{
          '--button-custom-background': Theme?.customButton?.background,
          '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
          '--button-custom-borderColor': Theme?.customButton?.borderColor,
          '--button-custom-textColor': Theme?.customButton?.textColor,
        }}
      >
        {Layout.hero?.layout !== 'lander' && <LazyCart />}
        <UpsellModal />
        <div style={{ width: '100%' }}>
          <Header />
        </div>
        <div>{Layout?.hero?.display && <div>{Layout.hero?.layout === 'lander' ? <Hero /> : <ProductHero />}</div>}</div>
        {Layout?.featuredIn?.display && (
          <LazyComponent Component={lazy(() => import('components/LogoSlider/LogoSlider'))} />
        )}
        {Layout?.products?.display && (
          <LazyComponent Component={lazy(() => import('components/ProductsSection/ProductSection'))} />
        )}
        {Layout?.videoTestimonials?.display && (
          <LazyComponent Component={lazy(() => import('components/VideoTestimonials/Testimonials'))}></LazyComponent>
        )}
        {Layout?.baComparison?.display && (
          <LazyComponent Component={lazy(() => import('components/Comparison/Comparison'))} />
        )}
        {Layout?.features?.display && (
          <LazyComponent Component={lazy(() => import('components/FeaturesList/FeaturesList'))} />
        )}
        {Layout?.setup?.display && <LazyComponent Component={lazy(() => import('components/ProductSetup/Setup'))} />}
        {Layout?.ribbon?.display && (
          <LazyComponent Component={lazy(() => import('components/SlidingTextBar/SlidingTextBar'))} />
        )}
        {Layout?.testimonialsSlider?.display && (
          <LazyComponent Component={lazy(() => import('components/TestimonialsSwiper/TestimonialSwiper'))} />
        )}
        {Layout?.competitorsComparison?.display && (
          <LazyComponent Component={lazy(() => import('components/CompetitorsComparison/CompetitorsComparison'))} />
        )}
        {Layout?.faq?.display && <LazyComponent Component={lazy(() => import('components/FAQ/FAQ'))}></LazyComponent>}
        {Layout?.reviews?.display && <LazyComponent Component={lazy(() => import('components/CustomerReviews'))} />}
        <LazyComponent Component={lazy(() => import('components/Footer/Footer'))} />
        {Layout?.floater?.display && <FloatingCTA />}
        <div>
          <Support />
        </div>
      </div>
      <LoadingOverlay />
    </>
  );
};

export default Layout;
