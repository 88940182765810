import { useCartActions } from 'lib/cartState/actions';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartSelectors } from 'lib/cartState/selectors';
import s from './Button.module.scss';
import { CSSProperties, useState } from 'react';
import axios from 'axios';

type CTAProps = {
  text: string;
  className: string;
  attribute?: string;
  style?: CSSProperties;
};

const Button = ({ text, className, attribute, style }: CTAProps) => {
  const { Theme, Settings, Layout } = usewpdataSelectors();
  const [isLoading, setIsLoading] = useState(false);
  const { CartData } = useCartSelectors();

  const isLink = Layout.hero.layout === 'lander';
  const cartDisabled = Settings.disableCart;
  const { toggleCartAction, toggleLoaderAction } = useCartActions();

  const handleSubmit = async () => {
    try {
      toggleLoaderAction();
      const url = '/wp-json/brands/cart/v1/session';
      const response = await axios.post(
        url,
        { payload: CartData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        const urlSymbol = Settings.link.includes('?') ? '&' : '?';
        toggleLoaderAction();
        window.location.href = `${Settings.link}/${urlSymbol}cart=${response.data.session_hash}&aff=${window.btoa(
          window.affiliate
        )}&entryUrl=${window.btoa(window.entry_url)}&postId=remote`;
      } else {
        toggleLoaderAction();
      }
    } catch (err) {
      toggleLoaderAction();
    }
  };

  if (isLink) {
    return (
      <a
        data-cy={attribute}
        style={style}
        href={Settings.link}
        data-theme={Theme?.button}
        className={`${className} cta ${s.cta}`}
      >
        {text}
      </a>
    );
  }

  return cartDisabled ? (
    <button
      style={style}
      data-cy={attribute}
      data-loading={isLoading}
      onClick={() => {
        handleSubmit();
      }}
      data-theme={Theme?.button}
      className={`${className} cta ${s.cta}`}
    >
      {text}
    </button>
  ) : (
    <button
      style={style}
      data-cy={attribute}
      onClick={toggleCartAction}
      data-theme={Theme?.button}
      className={`${className} cta ${s.cta}`}
    >
      {text}
    </button>
  );
};

export default Button;
